export const Actiontype = {
    SET_BANNER                  : "SET_BANNER",
    MINI_BANNER                 : "MINI_BANNER",
    FIRST_IMAGE_HOMEPAGE        : "FIRST_IMAGE_HOMEPAGE",
    SECOND_IMAGE_HOMEPAGE       : "SECOND_IMAGE_HOMEPAGE",
    THIRD_IMAGE_HOMEPAGE        : "THIRD_IMAGE_HOMEPAGE",
    HOMEPAGE_PRODUCT            : "HOMEPAGE_PRODUCT",
    MAIN_PRODUCT_LISTING        : "MAIN_PRODUCT_LISTING",
    MAIN_PRODUCT_LISTING_IMAGE  : "MAIN_PRODUCT_LISTING_IMAGE",
    PRODUCT_LISTING             : "PRODUCT_LISTING",
    ADDTOCARTPAGE               : "ADDTOCARTPAGE",
    ADDTOCARTSAVEDATA           : "ADDTOCARTSAVEDATA",
    ADDTOCARTSUBTOTAL           : "ADDTOCARTSUBTOTAL",
    AllSRORIES                  : "AllSRORIES",
    // FABRICDATA                  : "FABRICDATA",
    SEARCHDATA                  : "SEARCHDATA",
    WISHLIST                    : "WISHLIST",
    TERMS_CONDITION             : "TERMS_CONDITION",

}