import React from 'react';
import ReactDOM from 'react-dom';
import './css/style.css'
// import './css/home.css'
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import  store  from './redux/store';
import '../src/components/Front/Language/Language';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();



